const header = document.querySelector('.header');
const headerHeight = header.clientHeight;

function handleScroll() {
  const scrollY = window.scrollY || window.pageYOffset;

  if (scrollY >= headerHeight / 2) {
    header.setAttribute('data-bg', 'true');
  } else {
    header.removeAttribute('data-bg');
  }
}

// Call handleScroll on page load
handleScroll();

// Add event listener for scroll
window.addEventListener('scroll', handleScroll);

const burgerIcon = document.querySelector('.header__burger-icon');

burgerIcon.addEventListener('click', () => {
  if (header.hasAttribute('data-deployed')) {
    header.removeAttribute('data-deployed');
  } else {
    header.setAttribute('data-deployed', 'true');
  }
});

const menuItemHasChildren = document.querySelectorAll(
  '.header__menu > .menu-item.menu-item-has-children'
);

menuItemHasChildren.forEach((item) => {
  item.setAttribute('aria-expanded', 'false');
  if (window.innerWidth < 992) {
    item.addEventListener('click', (e) => {
      const isOpen = item.getAttribute('aria-expanded') === 'true';

      if (isOpen) {
        if (e.target.tagName.toLowerCase() === 'a') return;
        item.setAttribute('aria-expanded', 'false');
      } else {
        item.setAttribute('aria-expanded', 'true');
        e.preventDefault();
      }
    });
  }
});
